<template>
  <v-autocomplete
    :items="docs"
    :loading="loading"
    :label="label"
    v-model="item"
    item-text="name"
    item-value="name"
    :return-object="returnObject"
    :search-input.sync="searchInput"
    :rules="rules"
    :hide-details="!details"
    outlined
    dense
    clearable
    auto-select-first
    :prepend-icon="add ? 'mdi-plus-circle' : null"
    @click:prepend="$emit('new')"
    @change="updateValue"
    @click:clear="() => getFilterItems()"
    attach
  >
    <template v-slot:no-data>
      <div
        class="text-caption mx-5 font-weight-medium"
        v-text="'Type to search'"
      />
    </template>
  </v-autocomplete>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'autocomplete',
  props: {
    label: {
      type: String,
      default: () => ''
    },
    rules: {
      type: Array,
      default: () => []
    },
    value: {
      type: [Object, String],
      default: () => null
    },
    itemType: {
      type: String,
      default: () => null
    },
    add: {
      type: Boolean,
      default: () => false
    },
    exceptions: {
      type: [Array, Object],
      default: () => []
    },
    ocean: {
      type: String,
      default: () => null
    },
    details: {
      type: Boolean,
      default: () => false
    },
    returnObject: {
      type: Boolean,
      default: () => true
    },
    isRecapture: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => ({
    docs: [],
    search: null,
    loading: false,
    item: null
  }),
  async mounted() {
    if (typeof this.value === 'object' && !this.value?.name) {
      await this.getFilterItems();
    } else if (!this.value) {
      await this.getFilterItems();
    }
  },
  computed: {
    searchInput: {
      get: function () {
        return this.search;
      },
      set: async function (value) {
        if (typeof value === 'object') {
          value = value?.name;
        }

        if (value !== this.search) {
          this.search = value;

          const isSmallSize = smallSizeCategories.includes(this.itemType);
          let itemName = this.item;

          if (typeof this.item === 'object') {
            itemName = this.item?.name;
          }

          if (!isSmallSize && !itemName) {
            await this.getFilterItems(value);
          }
        }
      }
    }
  },
  watch: {
    value: {
      handler: 'locateItem',
      deep: true,
      immediate: true
    },
    ocean: {
      handler: 'handleOcean'
    },
    isRecapture: {
      handler: 'handleCondition'
    }
  },
  methods: {
    ...mapActions('query', ['getItems']),
    ...mapActions('entry', ['addItem']),
    itemExists(item) {
      return this.docs?.some((d) => d.name === item);
    },
    async createBoatIfNotExists(item) {
      const canCreate = !this.itemExists(item);
      if (canCreate) {
        const response = await this.addItem({
          item: 'boats',
          data: { name: item }
        });

        const { success } = response?.data || {};

        if (success) {
          await this.getFilterItems(item);
        }
      }
    },
    async locateItem(item, old) {
      try {
        if (item && typeof item === 'object') {
          let { name } = item || {};

          if (name === 'NULL' || name === 'UNKNOWN') {
            name = null;
          }

          if (name && name !== old?.name) {
            if (!this.docs.length) {
              await this.getFilterItems(name);
            }

            const exist = this.itemExists(name);

            if (!exist && this.itemType === 'boats') {
              this.createBoatIfNotExists(name);
            }
          }

          this.item = item;
          return;
        }

        if (item && item !== old) {
          if (!this.docs.length) {
            await this.getFilterItems(item);
          }

          const exist = this.itemExists(item);

          if (!exist && this.itemType === 'boats') {
            this.createBoatIfNotExists(name);
          }

          this.item = item;
          return;
        }

        this.item = item;
      } catch (error) {
        console.log(error);
      }
    },
    updateValue(item) {
      const { name, _id, legacyID } = item || {};
      const id = _id || legacyID;
      const payload = this.returnObject
        ? id
          ? { name, id, ...item }
          : name
        : item;
      this.$emit('input', payload);
    },
    getArray(data) {
      return Object.keys(data || {}).map((doc) => ({
        name: data[doc],
        abbr: doc
      }));
    },
    async handleOcean() {
      if (this.itemType === 'species') {
        await this.getFilterItems();
      }
    },
    async getFilterItems(name = '') {
      this.loading = true;

      const payload = {
        name,
        item: this.itemType,
        page: 1,
        limit: this.itemType === 'species' ? 100 : 10
      };

      const { data } = (await this.getItems(payload)) || {};
      this.docs = [];

      if (data?.docs) {
        const { docs } = data;
        this.docs = Array.isArray(docs) ? docs : this.getArray(docs);
      }

      if (this.exceptions?.length) {
        this.docs = this.docs?.filter(
          (item) =>
            !this.exceptions.some((exception) => exception.name === item.name)
        );
      }

      if (this.itemType === 'conditions' && !this.isRecapture) {
        this.docs = this.docs?.filter((item) => item.name !== 'DEAD');
      }

      this.itemType === 'species' && this.filterSpecies();

      this.loading = false;
    },
    filterSpecies() {
      switch (this.ocean) {
        case 'ATLANTIC':
          this.docs = this.docs.filter(
            (i) => i.acronym !== 'BK' && i.acronym !== 'ST'
          );
          break;

        case 'PACIFIC':
        case 'INDIAN':
          this.docs = this.docs.filter((i) => i.acronym !== 'WM');
          break;

        default:
          break;
      }

      !this.docs?.some((d) => d.name === this.value?.name) &&
        this.$emit('input', undefined);
    },
    async handleCondition() {
      await this.getFilterItems();
    }
  }
};

const smallSizeCategories = [
  'gears',
  'baits',
  'hooks',
  'conditions',
  'oceans',
  'species'
];
</script>
