<template>
  <v-card fluid :elevation="onlyForm ? 0 : 2">
    <v-toolbar flat dark color="primary" max-height="100px" v-if="!onlyForm">
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title> New Location </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="submit"> Save </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-text>
      <v-form ref="locationForm" class="mt-5">
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              label="LOCATION NAME"
              :rules="rule"
              v-model="input.name"
              hide-details
              outlined
              dense
              clearable
            />
          </v-col>
          <v-col cols="12" sm="6">
            <auto-complete
              label="OCEAN"
              :rules="rule"
              itemType="oceans"
              v-model="input.ocean"
            />
          </v-col>
        </v-row>

        <coordinates-form
          v-model="input.coordinates"
          ref="coordsForm"
          @change="setCoords"
        />

        <v-row>
          <v-col cols="12">
            <fishing-locations
              ref="fishingLocations"
              @coords="setCoords"
              draggable
              :input="input"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import { CoordinatesViewModel } from '../../viewmodels/index';
import { notifyMessage } from '../../../../helpers/handler';
import AutoComplete from '../../../../components/appComponents/AutoComplete.vue';
import CoordinatesForm from './CoordinatesForm.vue';
import FishingLocations from '../../../map/FishingLocations.vue';
export default {
  components: { AutoComplete, CoordinatesForm, FishingLocations },
  name: 'new-location',
  props: {
    onlyForm: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: window.structuredClone(INPUT)
    }
  },
  data: () => ({
    input: window.structuredClone(INPUT),
    rule: [(v) => !!v || 'Required']
  }),
  mounted() {
    if (!this.input['name']) {
      this.input = window.structuredClone(INPUT);
    }
    this.$refs.locationForm && this.$refs.locationForm.reset();
  },
  watch: {
    input: {
      handler: 'submitOnChange',
      deep: true
    },
    value: {
      handler: 'initForm'
    }
  },
  methods: {
    ...mapActions('entry', ['saveLocation']),
    validateForm() {
      let validCoords = false;
      if (this.$refs.coordsForm) {
        validCoords = this.$refs.coordsForm.validate();
      }

      let valid = false;
      if (this.$refs.locationForm) {
        valid = this.$refs.locationForm.validate();
      }

      return valid && validCoords;
    },
    async submit() {
      const valid = this.validateForm();
      if (valid) {
        this.input['approved'] = true;
        const response = await this.saveLocation(this.input);
        const { data } = response || {};
        const { success } = data || {};

        if (!success) {
          notifyMessage('Location already exists or something went wrong');
          this.$emit('close');
          return;
        }

        notifyMessage('Location successfully created');
        this.$emit('location', this.input);
        this.$emit('close');
      }
    },
    setCoords(coordinates) {
      if (!coordinates) {
        this.input.coordinates = new CoordinatesViewModel().coordinates;
        return;
      }
      this.input.coordinates = { ...coordinates };
    },
    submitOnChange(input) {
      const { ocean } = input;
      this.$emit('input', { ...input, ocean: { name: ocean } });
    },
    initForm(input) {
      const { ocean, coordinates: inputCoordinates } = input;
      const initial = { ...input, ocean: ocean?.name || ocean };

      const diffLocation =
        JSON.stringify(inputCoordinates) !==
        JSON.stringify(this.input.coordinates);
      const initialName = input.name || input.coordinates?.description;
      const inputName = this.input.name || this.input.coordinates?.description;

      if (diffLocation || initialName !== inputName) {
        this.input = window.structuredClone(initial);
      }
    }
  }
};

const INPUT = {
  name: null,
  ocean: null,
  coordinates: new CoordinatesViewModel().coordinates
};
</script>
