<template>
  <v-form ref="coordsForm">
    <v-dialog
      v-model="showCalculator"
      max-width="600px"
      scrollable
      persistent
      :fullscreen="full"
    >
      <v-card>
        <v-toolbar flat dark color="primary" max-height="100px">
          <v-toolbar-title> Convert from Decimal Degrees </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="showCalculator = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form ref="calcForm" class="mt-3">
            <v-row>
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="DDlatitude"
                  label="LATITUDE"
                  :rules="[rule, latRule].flat()"
                  type="number"
                  min="0"
                  max="90"
                  outlined
                  dense
                  clearable
              /></v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="DDlongitude"
                  label="LONGITUDE"
                  :rules="[rule, lngRule].flat()"
                  type="number"
                  min="0"
                  max="180"
                  outlined
                  dense
                  clearable
              /></v-col>
              <v-col cols="12" sm="4">
                <v-btn color="primary" block @click="updateCoords">
                  Set Marker
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12"> {{ conversionPreview }} </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row class="my-2 pb-2">
      <v-col cols="12" md="6">
        <h3 class="font-weight-medium">Latitude</h3>
        <v-row class="pt-1">
          <v-col cols="12" sm="4">
            <v-text-field
              v-model="input.latitude.degrees"
              label="DEGREES"
              :rules="[rule, latRule].flat()"
              type="number"
              min="0"
              max="90"
              hide-details
              outlined
              dense
              clearable
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              v-model="latMinutes"
              label="MINUTES"
              :rules="[rule, minutesRule].flat()"
              type="number"
              min="0"
              max="60"
              step="0.01"
              hide-details
              outlined
              dense
              clearable
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-autocomplete
              label="HEMISPHERE"
              v-model="input.latitude.hemisphere"
              :items="latHemisphere"
              :rules="rule"
              hide-details
              outlined
              dense
              clearable
              auto-select-first
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <h3 class="font-weight-medium">Longitude</h3>
        <v-row class="pt-1">
          <v-col cols="12" sm="4">
            <v-text-field
              v-model="input.longitude.degrees"
              label="DEGREES"
              :rules="[rule, lngRule].flat()"
              type="number"
              min="0"
              max="180"
              hide-details
              outlined
              dense
              clearable
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              v-model="lngMinutes"
              label="MINUTES"
              :rules="[rule, minutesRule].flat()"
              type="number"
              min="0"
              max="60"
              step="0.01"
              hide-details
              outlined
              dense
              clearable
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-autocomplete
              label="HEMISPHERE"
              v-model="input.longitude.hemisphere"
              :items="longHemisphere"
              :rules="rule"
              hide-details
              outlined
              dense
              clearable
              auto-select-first
            />
          </v-col>
        </v-row>
      </v-col>

      <v-col>
        <v-row class="justify-center">
          <span class="font-weight-light caption text-center">
            Click the <b>Set Marker</b> button to confirm map location
          </span>
        </v-row>
        <v-row class="justify-center py-0">
          <v-col class="py-0" v-if="!full">
            <v-checkbox
              v-model="activatePreviousLocations"
              hide-details
              label="Show previous fishing locations"
              class="shrink mr-2 mt-0 font-weight-light"
              v-if="previousLocations"
            />
          </v-col>
          <v-col class="text-center py-0">
            <v-btn small color="primary" @click="updateMap"> Set Marker </v-btn>
          </v-col>
          <v-col class="text-end py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  outlined
                  v-on="on"
                  v-bind="attrs"
                  color="primary"
                  @click="showCalculator = true"
                  class="ml-5"
                >
                  Coordinates conversion
                </v-btn>
              </template>
              <span>Calculate coordinates from Decimal Degrees</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import {
  LATITUDE_HEMISPHERE,
  LONGITUDE_HEMISPHERE,
  validCoordinates,
  reverseCoords
} from '../../../../helpers/handler';
import { CoordinatesViewModel } from '../../viewmodels';
export default {
  name: 'coordinates-form',
  props: {
    value: {
      type: Object,
      default: () => null
    },
    previousLocations: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    input: new CoordinatesViewModel().coordinates,
    rule: [(v) => !(v === null || v === undefined) || 'Required'],
    latRule: [(v) => (v >= 0 && v <= 90) || '0 - 90 range allowed'],
    lngRule: [(v) => (v >= 0 && v <= 180) || '0 - 180 range allowed'],
    minutesRule: [(v) => (v >= 0 && v <= 60) || '0 - 60 range allowed'],
    latHemisphere: LATITUDE_HEMISPHERE,
    longHemisphere: LONGITUDE_HEMISPHERE,
    showCalculator: false,
    DDlatitude: null,
    DDlongitude: null,
    activatePreviousLocations: false
  }),
  computed: {
    latMinutes: {
      get: function () {
        const min = this.input.latitude.minutes;
        const decimals = this.countDecimals(Number(min));
        return min !== null && decimals > 2 ? Number(min).toFixed(2) : min;
      },
      set: function (value) {
        this.input.latitude.minutes = value;
      }
    },
    lngMinutes: {
      get: function () {
        const min = this.input.longitude.minutes;
        const decimals = this.countDecimals(Number(min));
        return min !== null && decimals > 2 ? Number(min).toFixed(2) : min;
      },
      set: function (value) {
        this.input.longitude.minutes = value;
      }
    },
    conversionPreview() {
      const { errorBag } = this.$refs.calcForm || {};
      const invalid = Object.values(errorBag || {}).some(Boolean);

      if (!invalid && this.DDlatitude && this.DDlongitude) {
        const { latitude, longitude } = reverseCoords({
          lat: this.DDlatitude,
          lng: this.DDlongitude
        });

        const toStr = (coordinate) => {
          const { degrees, minutes, hemisphere } = coordinate || {};

          return `${degrees}° ${Number(minutes).toFixed(2)}' ${hemisphere}`;
        };

        return `Preview: ${toStr(latitude)} | ${toStr(longitude)}`;
      }

      return 'Input valid latitude and longitude';
    },
    full() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  },
  methods: {
    updateMap() {
      const valid = validCoordinates(this.input);
      if (valid) {
        this.$emit('change', this.input);
      } else {
        this.$refs.coordsForm && this.$refs.coordsForm.validate();
      }
    },
    updateForm(value) {
      this.input = { ...new CoordinatesViewModel().coordinates, ...value };
      this.$refs.coordsForm && this.$refs.coordsForm.resetValidation();
    },
    validate() {
      return this.$refs.coordsForm && this.$refs.coordsForm.validate();
    },
    countDecimals(value) {
      if (Math.floor(value) === value) return 0;
      return value.toString().split('.')[1]?.length || 0;
    },
    updateCoords() {
      const valid = this.$refs.calcForm && this.$refs.calcForm.validate();

      if (valid) {
        const { latitude, longitude } = reverseCoords({
          lat: this.DDlatitude,
          lng: this.DDlongitude
        });
        this.input = { ...this.input, latitude, longitude };
        this.updateMap();
        this.showCalculator = false;
      }
    }
  },
  watch: {
    value: {
      handler: 'updateForm',
      immediate: true
    },
    activatePreviousLocations: {
      handler: function (value) {
        this.$emit('activatePreviousLocations', value);
      },
      immediate: true
    }
  }
};
</script>
