<template>
  <v-container fluid class="down-top-padding">
    <v-dialog
      v-model="openDialog"
      :max-width="editableItem === 'locations' ? '860px' : '560px'"
      scrollable
      :fullscreen="full"
      persistent
    >
      <edit-item
        ref="editItem"
        @cancel="openDialog = false"
        @save="saveItem"
        :saving="saving"
        :type="editableItem"
      />
    </v-dialog>
    <v-card flat class="pt-5">
      <v-overlay :value="saving">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-row class="mx-5">
        <v-col cols="12" sm="4">
          <v-autocomplete
            label="Items"
            :items="items"
            item-text="name"
            item-value="value"
            v-model="editableItem"
            clearable
            @click:clear="clearRecords"
            @change="resetParams"
            dense
          />
        </v-col>
        <v-spacer />
        <v-col cols="12" sm="auto" v-if="editableItem">
          <v-btn color="primary" dark text @click="openEdit">
            Add new
            <v-icon right dark>mdi-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <list-view
        ref="listView"
        :title="''"
        :headers="headers"
        :records="records"
        :async-loading="loading"
        :footer-options="footer"
        :total-docs="totalDocs"
        item-key="_id"
        @onPaginate="(v) => (options = v)"
        searchable
        :filterable="editableItem === 'locations'"
        elevation="0"
      >
        <template slot="dropdown">
          <v-container id="dropdown-selector">
            <v-overflow-btn
              :items="tableModes"
              target="#dropdown-selector"
              v-model="tableSelection"
              hide-details
              class="dropdown"
            />
          </v-container>
        </template>
        <template slot="list-item" slot-scope="{ row }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-small
                color="primary"
                fab
                dark
                outlined
                elevation="0"
                class="mr-1"
                v-bind="attrs"
                v-on="on"
                @click="openEdit(row)"
              >
                <v-icon> mdi-pencil </v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-small
                color="red"
                fab
                dark
                outlined
                elevation="0"
                class="mr-1"
                v-bind="attrs"
                v-on="on"
                @click.stop="removeItem(row)"
              >
                <v-icon> mdi-delete </v-icon>
              </v-btn>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
      </list-view>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import ListView from '@/components/appComponents/ListView.vue';
import EditItem from '@/views/users/forms/sections/EditItem.vue';
import { notifyMessage, notifyConfirmation } from '../../helpers/notifications';
import { getUserInfo } from '../../helpers/user';
export default {
  name: 'edit-items',
  components: { ListView, EditItem },
  data: () => ({
    header: header,
    records: [],
    items: [
      { name: 'Tournaments', value: 'tournaments' },
      { name: 'Boats', value: 'boats' },
      { name: 'Species', value: 'species' },
      { name: 'Baits', value: 'baits' },
      { name: 'Gears', value: 'gears' },
      { name: 'Trophies', value: 'trophies' },
      { name: 'Locations', value: 'locations' }
    ],
    loading: false,
    saving: false,
    footer: {
      pageCount: 0,
      page: 0
    },
    totalDocs: 0,
    openDialog: false,
    options: null,
    itemsForRemove: [],
    editableItem: null,
    tableModes: [
      { value: 'all', text: 'All' },
      { value: 'approved', text: 'Approved' },
      { value: 'pending', text: 'Pending Approval' }
    ],
    tableSelection: 'all'
  }),
  mounted() {
    this.clearRecords();
  },
  updated() {
    this.updateQueryParams();
  },
  watch: {
    options: {
      handler: 'loadListView'
    },
    editableItem: {
      handler: 'loadListView'
    },
    tableSelection: {
      handler: 'loadListView'
    },
    '$router.query': {
      handler: 'updateQueryParams'
    }
  },
  computed: {
    headers() {
      return this.editableItem ? header[this.editableItem] : [];
    },
    full() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  },
  methods: {
    ...mapActions('query', ['getItems']),
    ...mapActions('entry', ['addItem', 'editItem', 'deleteItem']),
    updateQueryParams() {
      const { type, status } = this.$route.query;

      if (type) {
        const item = this.items.find((item) => item.value === type);
        this.editableItem = item?.value || null;
      }

      if (status) {
        const selection = this.tableModes.find((item) => item.value === status);
        this.tableSelection = selection?.value || null;
      }

      if (type || status) {
        this.$router.replace({ query: null });
      }
    },
    async loadListView() {
      const { search: name, page, itemsPerPage: limit } = this.options || {};
      if (this.editableItem?.length) {
        this.loading = true;
        const { data: pages } =
          (await this.getItems({
            name,
            item: this.editableItem,
            type: this.tableSelection,
            page,
            limit
          })) || {};
        this.records = pages?.docs || [];
        this.footer.pageCount = pages?.totalPages || 0;
        this.footer.page = pages?.page || 1;
        this.totalDocs = pages?.totalDocs || 0;
        this.loading = false;
      } else this.clearRecords();
    },
    removeItem(item) {
      notifyConfirmation(
        "This action can't be undone",
        'Are you sure to take this action?',
        async () => {
          this.saving = true;
          const { data } =
            (await this.deleteItem({
              item: this.editableItem,
              id: item._id
            })) || {};
          this.saving = false;
          if (data?.success) {
            this.loadListView();
            this.$refs.listView && this.$refs.listView.clearSearchAndRemove();
            notifyMessage('Successfully removed');
          } else notifyMessage('Something went wrong, please try again');
        }
      );
    },
    async saveItem(data) {
      this.saving = true;
      let response = null;
      const date = new Date();
      date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
      const user = getUserInfo();
      if (data?._id) {
        response = await this.editItem({
          item: this.editableItem,
          data: {
            ...data,
            updatedBy: user?.email,
            updatedDate: date.toISOString()
          }
        });
      } else {
        response = await this.addItem({
          item: this.editableItem,
          data: {
            ...data,
            enteredBy: user?.email,
            enteredDate: date.toISOString(),
            updatedBy: user?.email,
            updatedDate: date.toISOString()
          }
        });
      }
      this.saving = false;
      const { success } = response?.data || {};
      notifyMessage(
        success
          ? 'Successful operation'
          : 'Something went wrong, please try again'
      );
      success && (await this.loadListView());
      this.openDialog = false;
    },
    openEdit(data) {
      this.openDialog = true;
      setTimeout(() => {
        this.$refs.editItem && this.$refs.editItem.initialize(data);
      }, 50);
    },
    clearRecords() {
      this.records = [];
      this.footer = { pageCount: 0, page: 0 };
      this.totalDocs = 0;
      if (this.editableItem) {
        this.editableItem = null;
        this.options = null;
      }
    },
    resetParams() {
      this.tableSelection = 'all';
      this.$refs.listView && this.$refs.listView.resetParams();
    }
  }
};

const header = {
  tournaments: [
    {
      text: 'Name',
      align: 'start',
      sortable: false,
      value: 'name'
    },
    {
      text: 'Updated By',
      align: 'start',
      sortable: false,
      value: 'updatedBy'
    },
    {
      text: 'Updated Date',
      align: 'start',
      sortable: false,
      value: 'updatedDate'
    },
    { text: 'Actions', value: 'actions', sortable: false }
  ],
  boats: [
    {
      text: 'Name',
      align: 'start',
      sortable: false,
      value: 'name'
    },
    {
      text: 'Owner',
      align: 'start',
      sortable: false,
      value: 'fullName'
    },
    {
      text: 'Length',
      align: 'start',
      sortable: false,
      value: 'length'
    },
    {
      text: 'Make',
      align: 'start',
      sortable: false,
      value: 'make'
    },
    {
      text: 'Model',
      align: 'start',
      sortable: false,
      value: 'model'
    },
    {
      text: 'Entered By',
      align: 'start',
      sortable: false,
      value: 'enteredBy'
    },
    {
      text: 'Updated By',
      align: 'start',
      sortable: false,
      value: 'updatedBy'
    },
    {
      text: 'Updated Date',
      align: 'start',
      sortable: false,
      value: 'updatedDate'
    },
    { text: 'Actions', value: 'actions', sortable: false }
  ],
  species: [
    {
      text: 'Name',
      align: 'start',
      sortable: false,
      value: 'name'
    },
    {
      text: 'Updated By',
      align: 'start',
      sortable: false,
      value: 'updatedBy'
    },
    {
      text: 'Updated Date',
      align: 'start',
      sortable: false,
      value: 'updatedDate'
    },
    { text: 'Actions', value: 'actions', sortable: false }
  ],
  baits: [
    {
      text: 'Name',
      align: 'start',
      sortable: false,
      value: 'name'
    },
    {
      text: 'Acronym',
      align: 'start',
      sortable: false,
      value: 'acronym'
    },
    {
      text: 'Updated By',
      align: 'start',
      sortable: false,
      value: 'updatedBy'
    },
    {
      text: 'Updated Date',
      align: 'start',
      sortable: false,
      value: 'updatedDate'
    },
    { text: 'Actions', value: 'actions', sortable: false }
  ],
  gears: [
    {
      text: 'Name',
      align: 'start',
      sortable: false,
      value: 'name'
    },
    {
      text: 'Acronym',
      align: 'start',
      sortable: false,
      value: 'acronym'
    },
    {
      text: 'Updated By',
      align: 'start',
      sortable: false,
      value: 'updatedBy'
    },
    {
      text: 'Updated Date',
      align: 'start',
      sortable: false,
      value: 'updatedDate'
    },
    { text: 'Actions', value: 'actions', sortable: false }
  ],
  trophies: [
    {
      text: 'Name',
      align: 'start',
      sortable: false,
      value: 'name'
    },
    {
      text: 'Fisher Name',
      align: 'start',
      sortable: false,
      value: 'fisherName'
    },
    {
      text: 'Tagged Date',
      align: 'start',
      sortable: false,
      value: 'taggedDate'
    },
    { text: 'Actions', value: 'actions', sortable: false }
  ],
  locations: [
    {
      text: 'Name',
      align: 'start',
      sortable: false,
      value: 'name'
    },
    {
      text: 'Ocean',
      align: 'start',
      sortable: false,
      value: 'ocean.name'
    },
    {
      text: 'Updated By',
      align: 'start',
      sortable: false,
      value: 'updatedBy'
    },
    {
      text: 'Updated Date',
      align: 'start',
      sortable: false,
      value: 'updatedDate'
    },
    { text: 'Actions', value: 'actions', sortable: false }
  ]
};
</script>

<style>
.dropdown input {
  min-width: 0 !important;
}

.dropdown .v-select__selections {
  width: min-content;
}

.dropdown .v-input__slot {
  border: none;
  padding: 0;
  border-bottom: 1px solid black;
}
</style>
